import React from "react";
import Layout from "../components/layout";

const AboutPage = () => (
    <Layout pageTitle="Privacy">
        <h1>Privacy</h1>
        <p>We don't save any information about anything.</p>
    </Layout>
)

export default AboutPage

